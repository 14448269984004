export const NFTCart = ({ cartNFTs }) => {
    function getImage(nft) {
        let image;
        if (nft.contract?.openSea?.imageUrl) {
            image = nft.contract?.openSea?.imageUrl;
        }
        if (image === undefined && nft.media.length !== 0) {
            image = nft.media[0].gateway;
        }
        if (image === undefined && nft.tokenUri) {
            image = nft.tokenUri.gateway;
        }
        return image || "https://i.imgur.com/yT9EQzK.png";
    }

    return (
        
        <div className="flex flex-wrap gap-y-12 mt-4 w-44 gap-x-2 justify-center">
          
          {cartNFTs.map((nft, index) => (
            <div key={index}>
                <div className="rounded-md">
                    <img className="object-cover h-128 max-h-44 w-44 " src={getImage(nft)} alt={`NFT Preview ${index}`} />
                </div>
                <div className="flex flex-col y-gap-2 px-2 py-3 bg-slate-100 rounded-b-md h-110 border-solid border-5 border-indigo-500">
                    <div className="flex-grow mt-2">{`${nft.collectionName}`}</div>
                    <div className="break-words">
                      <p className="text-gray-600">#{`${nft.tokenId}`}</p>
                    </div>
                    
                </div>
              
              {/* Add other details you want to display */}
              <hr />
            </div>
          ))}
        </div>
      );
}