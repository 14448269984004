import './App.css';
import { Analytics } from '@vercel/analytics/react';
import { NFTCard } from "./components/nftCard";
import { ethers } from "ethers";
import { useState, useEffect } from 'react';
import { Alchemy, Network } from "alchemy-sdk";
import {NFTPreview} from "./components/nftPreview";
import {NFTCart} from "./components/nftCart";
import {NFTReceipt} from "./components/nftReceipt";
import abi from "./erc721-abi.json";
//change environments
import unsoldSepoliaAbi from "./sepolia-abi.json";
import unsoldMainAbi from "./main-abi.json";
import { useRef } from 'react';
import etherscanLogo from "./etherscan-logo.png";
//import unsoldLogo from "./unsoldnft_logo_1024.png";
import unsoldLogo from "./unsold_logo_text.png";


const config = {
  apiKey: "afJ5ybUFqqmc4eN_UmCEFAYJ52e8UGxj",
  network: Network.ETH_MAINNET,
  //network: Network.ETH_SEPOLIA,
};
const alchemy = new Alchemy(config);
const sepoliaUnsoldnfts = "0x424FC804d1F11c5a8Da9D3dB732BaD4C1Ef28A31";
const mainUnsoldnfts = "0x424FC804d1F11c5a8Da9D3dB732BaD4C1Ef28A31";
//const serviceFee = 1000000000000000;


function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [walletConnecting, setWalletConnecting] = useState(false);
  const [unsoldWalletAddress, setunsoldWalletAddress] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false); 
  const [isConfirmDisabled, setConfirmedDisabled] = useState(true); 
  const [isPreviewDisabled, setPreviewDisabled] = useState(true);
  const [confirmedText, setConfirmedText] = useState("0 NFT");
  const [previewText, setPreviewText] = useState("0 / 0 Collections");
  const [checked, setChecked] = useState(false);
  const [NFTs, setNFTs] = useState([]);
  const [PageKey, setPageKey] = useState("");
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  
  const [showConnect, setShowConnect] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [txUrl, setTxUrl] = useState("");
  
  //receipt data
  const [nftReceipt, setNftReceipt] = useState({});
  const [unsoldFee, setUnsoldFee] = useState("");
  const [gasFee, setGasFee] = useState("");
  const [nftPayment, setNftPayment] = useState("");
  //const [txBlockNumber, setTxBlockNumber] = useState("");
  const [provider, setProvider] = useState(null);
  const [groupedPreviewNFTs, setGroupedPreviewNFTs] = useState({});
  const [cartNFTs, setCartNFTs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupedNFTs, setGroupedNFTs] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [loadingCollections, setLoadingCollections] = useState({});
  const [sellProcessing, setSellProcessing] = useState(false);
  const emailInputRef = useRef(null);
  const [emailSubmit, setEmailSubmit] = useState(false);
  const isFetchingDataRef = useRef(false);
  const [initialNftload, setInitialNFTs] = useState(true);
  const [showNFTs, setShowNFTs] = useState(true);
  //const [email, setEmail] = useState("");
  const [approvedNFTs, setApprovedNFTs] = useState([]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const userEmail = emailInputRef.current.value;
    console.log('User Email:', userEmail);
    console.log('nftReceipt.blockNumber:', nftReceipt.blockNumber);
    //const signer = await provider.getSigner();
    //const unsoldContract = new ethers.Contract(sepoliaUnsoldnfts, unsoldSepoliaAbi, signer);

    //The response fetches the transactions the specified addresses.
    let transferResponse = await alchemy.core.getAssetTransfers({
      fromBlock: nftReceipt.blockNumber,
      toBlock: nftReceipt.blockNumber,
      fromAddress: walletAddress,
      toAddress: unsoldWalletAddress,
      excludeZeroValue: true,
      category: ["erc721", "erc1155"],
      withMetadata: true,
    })

    // Extracting common data for the receiptBlock
    const receiptBlock = {
      id: transferResponse.transfers[0].hash, // Assuming the hash is the same for all transfers
      blockNumber: nftReceipt.blockNumber,
      date: transferResponse.transfers[0].metadata.blockTimestamp, // Assuming the blockTimestamp is the same for all transfers
      from: transferResponse.transfers[0].from, // Assuming the from address is the same for all transfers
      to: transferResponse.transfers[0].to, // Assuming the to address is the same for all transfers
      serviceFee: ethers.formatUnits(unsoldFee),
      txFee: ethers.formatUnits(gasFee),
      sellAmount: ethers.formatUnits(nftPayment),
      nfts: [],
    };

    // Extracting data for each NFT transfer
    transferResponse.transfers.forEach((transfer) => {
      const nft = {
        category: transfer.category,
        tokenId: transfer.category === 'erc721' ? ethers.toNumber(transfer.erc721TokenId) : ethers.toNumber(transfer.erc1155Metadata[0].tokenId),
        collectionAddress: transfer.rawContract.address,
        collectionName: '', // You mentioned leaving this blank, modify if needed
        collectionSymbol: '',
      };

      receiptBlock.nfts.push(nft);
    });

    // Now, receiptBlock contains the transformed data
    console.log(receiptBlock);

    //Logging the response to the console
    console.log("transferResponse: ", transferResponse);

    const updatedReceiptBlock = { ...receiptBlock }; // Create a copy of receiptBlock
    updatedReceiptBlock.nfts = updatedReceiptBlock.nfts.map((nft) => {
      const collection = cartNFTs.find(
        (item) => item.contract.address === nft.collectionAddress
      );

      if (collection) {
        return {
          ...nft,
          collectionName: collection.contract.name,
          collectionSymbol: collection.contract.symbol,
        };
      }

      return nft;
    });

    console.log("updatedReceiptBlock: ", updatedReceiptBlock);
    
    try {
      const serverEndpoint = 'https://unsoldnfts.com/app/nftreceipt.php';
  
      const response = await fetch(serverEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          receipt: JSON.stringify(updatedReceiptBlock), // Assuming receipt is an object
          //receipt: JSON.stringify(groupedNFTs), // Assuming receipt is an object
        }),
      });
  
      if (response.ok) {
        console.log('Receipt information sent successfully!');
        setEmailSubmit(true);
        // You may want to reset the form or show a success message to the user
      } else {
        console.error('Failed to send receipt information.');
        // Handle the error accordingly
      }
    } catch (error) {
      console.error('Error sending receipt information:', error);
      // Handle the error accordingly
    }
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  // Assuming groupedPreviewNFTs is an object where each key represents a collection
  //const areAllCollectionsApproved = Object.values(groupedPreviewNFTs).every((collection) => collection.approved);

  const updateCartNFTs = () => {

    console.log("groupedPreviewNFTs:", groupedPreviewNFTs);

    // Filter nfts with approved: true
    // const approvedNFTs = Object.values(groupedPreviewNFTs).reduce((result, collection) => {
    //   console.log(`Collection: ${collection.collectionName}, NFTs:`, collection.nfts);
      
    //   const approvedCollectionNFTs = collection.nfts.filter((nft) => nft.approved);
    //   return result.concat(approvedCollectionNFTs);
    // }, []);

    const approvedNFTs = Object.entries(groupedPreviewNFTs).reduce((result, [collectionName, collection]) => {
      if (collection.approved) {
        // If the collection is approved, include its nfts in the result
        return result.concat(collection.nfts.map((nft) => ({ ...nft, collectionName })));
      }
      return result;
    }, []);

    console.log("approvedNFTs... ", approvedNFTs);

    // Sort the approvedNFTs array alphabetically by collectionName and tokenID
    const sortedCartNFTs = approvedNFTs.sort((a, b) => {
      // Sort by collectionName
      const collectionComparison = a.collectionName.localeCompare(b.collectionName);

      // If collectionName is the same, sort by tokenID
      return collectionComparison === 0 ? a.tokenID - b.tokenID : collectionComparison;
    });

    // Set the cartNFTs state
    console.log("sortedCartNFTs: ", sortedCartNFTs);
    setCartNFTs(sortedCartNFTs);
  };

  // Function to toggle between different states
  const toggleComponent = (component) => {
    switch (component) {
      case 'connect':
        setShowPreview(false);
        setShowConnect(true);
        setShowCard(false);
        setShowCart(false);
        setShowReceipt(false);
        break;
      case 'preview':
        setShowPreview(true);
        setShowConnect(false);
        setShowCard(false);
        setShowCart(false);
        setShowReceipt(false);
        break;
      case 'card':
        setShowPreview(false);
        setShowConnect(false);
        setShowCard(true);
        setShowCart(false);
        setShowReceipt(false);
        break;
      case 'cart':
        setShowPreview(false);
        setShowConnect(false);
        setShowCard(false);
        setShowCart(true);
        setShowReceipt(false);
        break;
      case 'receipt':
        setShowPreview(false);
        setShowConnect(false);
        setShowCard(false);
        setShowCart(false);
        setShowReceipt(true);
        break;
      default:
        // Handle default case if needed
    }
  };

  // Function to set loading state for a specific collection
  const setLoadingCollection = (collectionName, isLoading) => {
    setLoadingCollections((prevLoadingCollections) => ({
      ...prevLoadingCollections,
      [String(collectionName)]: isLoading,
    }));
    //console.log("setLoadingCollection...", setLoadingCollection);
  };

  // const addSelectedNFT = (collectionName, index) => {
  //   const selectedKey = `${collectionName}_${index}`;
  
  //   setSelectedNFTs(prevSelectedNFTs => {
  //     if (prevSelectedNFTs.includes(selectedKey)) {
  //       return prevSelectedNFTs.filter(storedKey => storedKey !== selectedKey);
  //     } else {
  //       return [...prevSelectedNFTs, selectedKey];
        
  //     }
  //   });
  //   console.log("selectedNFTs: ", selectedNFTs);
  // };

  const addSelectedNFT = (collectionName, index) => {
    const selectedKey = `${collectionName}_${index}`;

    setDataFetched(false);
    console.log("dataFetched:;;;;;;;;;;;; ",dataFetched);
  
    setSelectedNFTs((prevSelectedNFTs) => {
      console.log("Previous Selected NFTs:", prevSelectedNFTs);
  
      const isSelected = prevSelectedNFTs.includes(selectedKey);
  
      if (isSelected) {
        const updatedNFTs = prevSelectedNFTs.filter((storedKey) => storedKey !== selectedKey);
        console.log("Updated Selected NFTs:", updatedNFTs);
        return updatedNFTs;
      } else {
        const updatedNFTs = [...prevSelectedNFTs, selectedKey];
        console.log("Updated Selected NFTs:", updatedNFTs);
        return updatedNFTs;
      }
    });
    
    
    console.log("Current Selected NFTs:", selectedNFTs);
  };
  
  const connectWallet = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        setWalletConnecting(true);
        /* MetaMask is installed */
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        }).then(setButtonDisabled(true));
        
        
        const tempProvider = new ethers.BrowserProvider(window.ethereum);
        setProvider(tempProvider);
        setWalletAddress(accounts[0]);
        console.log("connectWallet..");
        console.log(accounts[0]);

        toggleComponent('card');

        //get account
        main(accounts[0],PageKey);

      } catch (err) {
        console.error(err.message);
      }
      finally {
        setWalletConnecting(false);
      }
    } else {
      setButtonDisabled(false);
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const getCurrentWalletConnected = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        
        if (accounts.length > 0) {
          const tempProvider = new ethers.BrowserProvider(window.ethereum);
          setProvider(tempProvider);
          setWalletAddress(accounts[0]);
          console.log(accounts[0]);
          console.log("getCurrentWalletConnected..");
          //get account
          main(accounts[0],PageKey);
          console.log("main......");
        } else {
          setButtonDisabled(false);
          console.log("Connect to MetaMask using the Connect button");
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      setButtonDisabled(false);
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const addWalletListener = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts.
          toggleComponent('connect');
          console.log('Please connect to MetaMask.');
        } else {
        
        const tempProvider = new ethers.BrowserProvider(window.ethereum);
        setProvider(tempProvider);
        setWalletAddress(accounts[0]);
        console.log("yoyo", accounts[0]);
        }
      });
      
    } else {
      /* MetaMask is not installed */
      setButtonDisabled(false);
      setWalletAddress("");
      console.log("Please install MetaMask");
    }
  };

  // setGroupedNFTs((prevGroupedNFTs) => ({
  //   ...prevGroupedNFTs,
  //  ...(NFTs.ownedNfts ?? []).reduce((groups, nft) => {
  //   const collectionName = nft.contract.name;
    
  //   if (!groups[collectionName]) {
  //     groups[collectionName] = [];
  //   }
  
  //   groups[collectionName].push(nft);
  
  //   return groups;
  //   }, {}),
  // }));

  const initialGroupedNFTs = () => {
    setGroupedNFTs((prevGroupedNFTs) => {
      const newGroupedNFTs = { ...prevGroupedNFTs };
      console.log("....Before filtering, selectedNFTs:", selectedNFTs);
      console.log("....Before filtering, newGroupedNFTs:", newGroupedNFTs);
      //(NFTs.ownedNfts ?? []).forEach((nft) => {
      (NFTs.ownedNfts || []).forEach((nft) => {
        const collectionName = nft.contract.name;
  
        if (!newGroupedNFTs[collectionName]) {
          newGroupedNFTs[collectionName] = [];
        }
  
        // Check if the NFT is already in the collection
        const isNFTAlreadyAdded = newGroupedNFTs[collectionName].some((existingNFT) => existingNFT.tokenId === nft.tokenId);

        if (!isNFTAlreadyAdded) {
        newGroupedNFTs[collectionName].push(nft);
        }

      });

      //console.log("before setInitialNFTs....", initialNftload);
      //run on subsequent process, not on initial load
      // if (!initialNftload) {
      // // Remove unselected items
      // Object.keys(newGroupedNFTs).forEach((collectionName) => {
      //   newGroupedNFTs[collectionName] = newGroupedNFTs[collectionName].filter(
      //     (nft) => selectedNFTs.includes(`${collectionName}_${nft.index}`)
      //   );
      // });
      
      // }

      //setInitialNFTs(false);
      //console.log("after setInitialNFTs....", initialNftload);

      // Sort the keys alphabetically
      //const sortedKeys = Object.keys(newGroupedNFTs).sort();
      const sortedKeys = Object.keys(newGroupedNFTs).sort((a, b) => a.localeCompare(b));


      // Create a new object with sorted keys
      const sortedGroupedNFTs = sortedKeys.reduce((sorted, key) => {
        sorted[key] = newGroupedNFTs[key];
        return sorted;
      }, {});
  
      //return newGroupedNFTs;
      return sortedGroupedNFTs;
    });
  };

  const updateGroupedNFTs = () => {
    setGroupedNFTs((prevGroupedNFTs) => {
      const newGroupedNFTs = { ...prevGroupedNFTs };

      console.log("Before filtering, selectedNFTs:", selectedNFTs);
    console.log("Before filtering, newGroupedNFTs:", newGroupedNFTs);
  
      //(NFTs.ownedNfts ?? []).forEach((nft) => {
      // (NFTs.ownedNfts || []).forEach((nft) => {
      //   const collectionName = nft.contract.name;
  
      //   if (!newGroupedNFTs[collectionName]) {
      //     newGroupedNFTs[collectionName] = [];
      //   }
  
      //   // Check if the NFT is already in the collection
      //   const isNFTAlreadyAdded = newGroupedNFTs[collectionName].some((existingNFT) => existingNFT.tokenId === nft.tokenId);

      //   if (!isNFTAlreadyAdded) {
      //   newGroupedNFTs[collectionName].push(nft);
      //   }

      // });

      // Filter out keys that don't correspond to existing collections
        const validKeys = selectedNFTs.filter((key) =>
        Object.keys(newGroupedNFTs).some((collectionName) =>
          key.startsWith(`${collectionName}_`)
        )
      );

      // Use the filtered keys for filtering
      Object.keys(newGroupedNFTs).forEach((collectionName) => {
        newGroupedNFTs[collectionName] = newGroupedNFTs[collectionName].filter(
          (nft) => validKeys.includes(`${collectionName}_${nft.index}`)
        );
      });
      // validKeys.forEach((key) => {
      //   const [collectionName, index] = key.split('_');
      //   newGroupedNFTs[collectionName] = newGroupedNFTs[collectionName].filter(
      //     (nft) => nft.index === parseInt(index, 10)
      //   );
      // });

      //console.log("before setInitialNFTs....", initialNftload);
      //run on subsequent process, not on initial load
      // if (!initialNftload) {

      // Remove unselected items
      // Object.keys(newGroupedNFTs).forEach((collectionName) => {
      //   newGroupedNFTs[collectionName] = newGroupedNFTs[collectionName].filter(
      //     (nft) => selectedNFTs.includes(`${collectionName}_${nft.index}`)
      //   );
      // });

      console.log("After filtering, newGroupedNFTs:", newGroupedNFTs);
      
      // }

      //setInitialNFTs(false);
      //console.log("after setInitialNFTs....", initialNftload);

      // Sort the keys alphabetically
      //const sortedKeys = Object.keys(newGroupedNFTs).sort();
      const sortedKeys = Object.keys(newGroupedNFTs).sort((a, b) => a.localeCompare(b));


      // Create a new object with sorted keys
      const sortedGroupedNFTs = sortedKeys.reduce((sorted, key) => {
        sorted[key] = newGroupedNFTs[key];
        return sorted;
      }, {});
  
      //return newGroupedNFTs;
      return sortedGroupedNFTs;
    });
  };

  const main = async (address, xpageKey) => {
    // Provided below is a sample address
    // Configure accordingly
    //walletAddress
    // console.log({walletAddress});
    
    //address = "vitalik.eth";
    //address = "0xcc03C4cA24abAB228b79fc6f98834a6e5638336a"; //unsellable
    // const address = {walletAddress};
    //address = "0x984091351b5f9fb264288f103963e209446c5a05";
    //console.log("xpageKey", xpageKey);
    const nfts = await alchemy.nft.getNftsForOwner(address,
      {
        //excludeFilters: ["SPAM", "AIRDROPS"],
        excludeFilters: ["SPAM"],
        pageKey: xpageKey,
      }
      );

    if (nfts) {
      setNFTs(prevNFTs => {
        const updatedNFTs = {
          ...prevNFTs,
          ownedNfts: [
            ...(prevNFTs.ownedNfts || []),
            ...nfts.ownedNfts.filter(newNFT => 
              !((prevNFTs.ownedNfts || []).some(existingNFT => existingNFT.tokenId === newNFT.tokenId))
            ),
          ],
        };
    
        return updatedNFTs;
      });
    
      if (nfts.pageKey) {
        // If there is a pageKey, call main again with the new pageKey
        main(address, nfts.pageKey);
      }

    if (nfts.pageKey?.length > 0) {
      //setPageKey(nfts.pageKey);
      console.log("walletAddress=", address);
      //console.log("NFTs.ownedNfts???", NFTs.ownedNfts);
      console.log('first pageKey?:::::', nfts.pageKey);
      //setNFTs(nfts).then(main(address, nfts.pageKey));
      //setNFTs(nfts);
    }
    // else {
    //   setNFTs(nfts);
    //   //setPageKey("");
    // }

    // Print NFT details
    // Refer to https://docs.alchemy.com/reference/getnfts to print more fields
    // NFTs.ownedNfts.forEach((NFTs) => {
    //   console.log("Name:", NFTs.contract.name);
    //   console.log("Contract Address:", NFTs.contract.address);
    //   console.log("Token ID:", NFTs.tokenId);
    //   console.log("Token Type:", NFTs.media);
    //   console.log("----------------------------------");
    // });

    } //end if(nfts)

  };

  const sellNfts = async () => {
    // Create arrays to store collection contract addresses, token IDs, and amounts
    const collectionAddresses = [];
    const tokenIds = [];
    const amounts = [];
  
    // Process each item in cartNFTs
    cartNFTs.forEach((nft) => {
      // Assuming nft has properties collectionName, tokenID, and amount
      //const { collectionName, tokenID, amount } = nft;
  
      // Add data to respective arrays
      collectionAddresses.push(nft.contract.address); // Replace with the actual contract address
      tokenIds.push(nft.tokenId);
      amounts.push(1);
    });
  
    // Now, finalNFTs is an array of three nested arrays
    const finalNFTs = [collectionAddresses, tokenIds, amounts];
  
    // You can now use finalNFTs to interact with the blockchain or perform other actions
    //console.log("Final NFTs:", finalNFTs);

    
  
    //interact with the blockchain
    try {

      setSellProcessing(true);
      const signer = await provider.getSigner();

      //change environments
      //const unsoldContract = new ethers.Contract(sepoliaUnsoldnfts, unsoldSepoliaAbi, signer);
      const unsoldContract = new ethers.Contract(mainUnsoldnfts, unsoldMainAbi, signer);
      
      const serviceFee = await unsoldContract.getServiceFeeAmount();
      const ourWallet = await unsoldContract.getOurWalletAddress();
      const nftpayment = await unsoldContract.getNftPaymentAmount();
      setunsoldWalletAddress(ourWallet);
      console.log("serviceFee: ", serviceFee);
      //const serviceFeeInWei = ethers.toNumber(serviceFee);
      //console.log("serviceFeeInWei: ", serviceFeeInWei);
      // Calculate the total service fee amount based on the number of NFTs
      //const numberOfNfts = parseInt(collectionAddresses.length);
      const numberOfNfts = ethers.toBigInt(collectionAddresses.length);
      const totalServiceFeeAmount = serviceFee * numberOfNfts;
      const totalPaymentAmount = nftpayment * numberOfNfts;
      
      
      //console.log("totalServiceFeeAmount: ", totalServiceFeeAmount);

      try{
      
      const txResponse = await unsoldContract.sellNfts(collectionAddresses, tokenIds, amounts, {
        value: totalServiceFeeAmount,
      });
      
      const receipt = await txResponse.wait();

    
      //console.log("txResponse:", txResponse);
      //console.log("receipt:", receipt);
      setNftReceipt(receipt);
      // Assuming you have the transaction hash in the `receipt` object
      const transactionHash = receipt.hash;
      setGasFee(receipt.gasUsed * receipt.gasPrice);
      setUnsoldFee(totalServiceFeeAmount);
      setNftPayment(totalPaymentAmount);
      //const transactionBlockNumber = receipt.blockNumber;

    

      // Construct the Etherscan URL
      //const etherscanBaseUrl = "https://sepolia.etherscan.io/tx/";
      const etherscanBaseUrl = "https://etherscan.io/tx/";
      const fullTxUrl = etherscanBaseUrl + transactionHash;

      // Set the URL in the state
      setTxUrl(fullTxUrl);

    

      //console.log("txResponse?", txResponse);
      //console.log("Transaction mined. Receipt:", receipt);
      // You can now access various information from the receipt, such as transaction hash, block number, gas used, etc.
      //console.log("Transaction Hash:", receipt.hash);
      //console.log("Block Number:", receipt.blockNumber);
      //console.log("Gas Used:", receipt.gasUsed);
  
      
        
        toggleComponent('receipt');
        return receipt;
      }catch(error){
        if(error.toString().includes('missing v')){
          console.log("missing v");
          toggleComponent('receipt');
        }else{
            //reject(hash);
        }
      }
      
      
  
      } catch (err) {
        console.error("Contract sellNFTs error:", err);
        
      } finally {
        setSellProcessing(false);
      }

  };

  const getCollectionApproval = async (groupedPreviewNFTs, collectionName, flag) => {
    console.log("getCollectionApproval.........", groupedPreviewNFTs[collectionName].nfts[0].contract.address);
    try {
    const signer = await provider.getSigner();
    const collectionContract = new ethers.Contract(groupedPreviewNFTs[collectionName].nfts[0].contract.address, abi, signer);
    
    //const approval = await collectionContract.setApprovalForAll(sepoliaUnsoldnfts, flag);
    const approval = await collectionContract.setApprovalForAll(mainUnsoldnfts, flag);
    
    await approval.wait();
    console.log("approved?", approval);

    if (approval) {
      //console.log("approval:", approval);
      // If approval is successful, update approvedNFTs using setApprovedNFTs
      // const nftData = await groupedPreviewNFTs[collectionName].nfts.map((nft) => ({
      //   // collectionAddress: groupedPreviewNFTs[collectionName][0].contract.address,
      //   // tokenID: nft.tokenId,
      //   // amount: 1, // Adjust this based on your actual NFT data structure
      //   ...nft,
      //   approved: flag,
      // }));

      // // Update the approved property for each NFT individually
      // groupedPreviewNFTs[collectionName].nfts = nftData;

      //setApprovedNFTs((prevNFTs) => [...prevNFTs, ...nftData]);
      // setApprovedNFTs((prevNFTs) => [
      //   ...prevNFTs,
      //   { collectionName, approved: true, nfts: nftData },
      // ]);
      //console.log("getCollectionApproval:", groupedPreviewNFTs);
      //return { approval: flag };
    // } catch (approvalError) {
    //   console.error("Approval error:", approvalError);
    //   return { approval: false };
      //await fetchData();
      setDataFetched(false);
       return approval;
     }

    //console.log("approvedNFTs:", approvedNFTs);

    //return approval;

    } catch (err) {
      console.error("Contract initialization error:", err);
      setDataFetched(false);
      return { approval: false };
    }

    
  
  
  };

  const checkCollectionApproval = async (collectionAddress) => {
    //console.log("getCollectionApproval.........", groupedPreviewNFTs[collectionName].nfts[0].contract.address);
    try {
    const signer = await provider.getSigner();
    const collectionContract = new ethers.Contract(collectionAddress, abi, signer);
    
    //const approved = await collectionContract.isApprovedForAll(walletAddress, sepoliaUnsoldnfts);
    const approved = await collectionContract.isApprovedForAll(walletAddress, mainUnsoldnfts);
    
    console.log("approved?", approved);

    return approved;

    } catch (err) {
      console.error(err.message);
      return false;
    }
  
  
  };

  const fetchData = async () => {
    
    console.log("Selected NFTs before fetching data:", selectedNFTs);

    const updatedGroupedPreviewNFTs = { ...groupedPreviewNFTs };

    // Create an array of promises
    //const approvalPromises = selectedNFTs.map(async (indexString) => {
    for (const indexString of selectedNFTs) {
      const [collectionName] = indexString.split('_');
      const isApproved = await checkCollectionApproval(groupedNFTs[collectionName][0]?.contract?.address);

      console.log("isApproved:", isApproved);

      if (isApproved) {
      console.log("isApproved is truthy...");
      }

      // if (!groupedPreviewNFTs[collectionName]) {
      //   //groupedPreviewNFTs[collectionName] = { nfts: [], approved: isApproved };
      //   groupedPreviewNFTs[collectionName] = { nfts: [] };
      // }

      if (!updatedGroupedPreviewNFTs[collectionName]) {
        updatedGroupedPreviewNFTs[collectionName] = { nfts: [] };
      }

      console.log("after isApproved.....");
      //groupedPreviewNFTs[collectionName].approved = isApproved;
      updatedGroupedPreviewNFTs[collectionName].approved = isApproved;

      const indexNumber = parseInt(indexString.split('_')[1], 10);
      const NFT = groupedNFTs[collectionName][indexNumber];

      // const isNFTAlreadyAdded = groupedPreviewNFTs[collectionName].nfts.some(
      //   (existingNFT) => existingNFT.index === indexNumber);

      const isNFTAlreadyAdded = updatedGroupedPreviewNFTs[collectionName].nfts.some(
        (existingNFT) => existingNFT.index === indexNumber
      );

        // const isNFTAlreadyAdded = groupedPreviewNFTs[collectionName].nfts.some(
        //   (existingNFT, existingIndex) => {
        //     if (existingNFT.index === indexNumber) {
        //       // Remove the existing NFT if already added
        //       groupedPreviewNFTs[collectionName].nfts.splice(existingIndex, 1);
        //       return true;
        //     }
        //     return false;
        //   }
        // );
      
      
        // Only add the NFT if it's not already in the list
        // if (!isNFTAlreadyAdded) {
        // groupedPreviewNFTs[collectionName].nfts.push({ ...NFT, index: indexNumber });
        // }

        if (!isNFTAlreadyAdded) {
          // Only add the NFT if it's not already in the list
          updatedGroupedPreviewNFTs[collectionName].nfts.push({ ...NFT, index: indexNumber });
        }

      
    }
    //);

     // Remove items not present in selectedNFTs
    for (const collectionName in updatedGroupedPreviewNFTs) {
      updatedGroupedPreviewNFTs[collectionName].nfts = updatedGroupedPreviewNFTs[collectionName].nfts.filter(
        (existingNFT) =>
          selectedNFTs.includes(`${collectionName}_${existingNFT.index}`)
      );

      // Check if there are no NFTs selected for the current collection
      if (updatedGroupedPreviewNFTs[collectionName].nfts.length === 0) {
        delete updatedGroupedPreviewNFTs[collectionName];
      }
    }

    console.log("updating groupedPreviewNFTs:", groupedPreviewNFTs);

    // Use Promise.all to wait for all promises to resolve
    //await Promise.all(approvalPromises);

    // Set the state with the updated groupedPreviewNFTs
    
    //setGroupedPreviewNFTs({ ...groupedPreviewNFTs });
    setGroupedPreviewNFTs(updatedGroupedPreviewNFTs);
    //setGroupedPreviewNFTs((prevGroupedPreviewNFTs) => ({ ...prevGroupedPreviewNFTs }));
    console.log("groupedPreviewNFTsgroupedPreviewNFTs:", groupedPreviewNFTs);
    setLoading(false);

    // Set dataFetched to true once data is fetched
    setDataFetched(true);
  };

  useEffect(() => {
    getCurrentWalletConnected();
    addWalletListener();
  }, [walletAddress]);

  useEffect(() => {
    console.log("total NFTs..",NFTs);
    // if (nfts.pageKey?.length > 0) {
    //   //   //setPageKey(nfts.pageKey);
    // }
  }, [NFTs]);

  useEffect(() => {
    // This code will run whenever the state is updated
    //if (NFTs) {
     // main(walletAddress, PageKey);
    //}
  }, [walletAddress,PageKey]); // Add NFTs as a dependency so that the effect runs when NFTs changes

  // useEffect(() => {
  //   //fetchData();
  // }, []);

  useEffect(() => {
    // Run fetchData only when selectedNFTs changes and data has not been fetched yet
    if (!dataFetched) {
      console.log("Fetching data...");
    //console.log("Selected NFTs:", selectedNFTs);
      fetchData();
    }
  //}, [selectedNFTs, getCollectionApproval]);
    }, [ getCollectionApproval]);
  
  // useEffect(() => {
  //   console.log("Effect triggered");
  //   // This effect will run only when groupedNFTs changes
  //   // and data has already been fetched
  //   if (dataFetched) {
  //     fetchData();
  //   }
  // }, [groupedNFTs, dataFetched]);

        // useEffect(() => {
        //   // This effect will run only when groupedNFTs changes
        //   // and data has already been fetched
        //   if (dataFetched && !isFetchingDataRef.current) {
        //     isFetchingDataRef.current = true;
        //     fetchData().finally(() => {
        //       isFetchingDataRef.current = false;
        //     });
        //   }
        // }, [groupedNFTs, dataFetched]);

  useEffect(() => {
    console.log("NFTs.ownedNfts changed...");
    initialGroupedNFTs();
    
  }, [NFTs.ownedNfts]); // Only run when ownedNfts changes
  //}, [selectedNFTs, NFTs.ownedNfts]); // Only run when ownedNfts changes

  useEffect(() => {
    
    // Check the length of the selectedNFTs array
    const hasSelectedNFTs = selectedNFTs.length;
    console.log("selectedNFTs changed...", hasSelectedNFTs);
    
    if (hasSelectedNFTs === 0) {
      setConfirmedText(`${hasSelectedNFTs} NFTs`);
      setConfirmedDisabled(true);
        
    } else if (hasSelectedNFTs === 1) {
      setConfirmedText(`${hasSelectedNFTs} NFT`);
      setConfirmedDisabled(false);
      console.log("setConfirmedDisabled changed...false");
      
    } else if (hasSelectedNFTs > 1) {
      setConfirmedText(`${hasSelectedNFTs} NFTs`);
      setConfirmedDisabled(false);
        
    } else {
      setConfirmedText(`${hasSelectedNFTs} NFTs`);
      setConfirmedDisabled(true);   
    }
    
  //}, [NFTs.ownedNfts]); // Only run when ownedNfts changes
  }, [selectedNFTs]); // Only run when selectedNFTs changes

  useEffect(() => {

    // Assuming groupedPreviewNFTs is an object where each key represents a collection
    const approvedCollections = Object.values(groupedPreviewNFTs).filter((collection) => collection.approved);

    const areAllCollectionsApproved = approvedCollections.length === Object.values(groupedPreviewNFTs).length;

    // Update the previewText based on the number of approved collections
    setPreviewText(`${approvedCollections.length} / ${Object.values(groupedPreviewNFTs).length} Collections`);

    // Update the button disabled state based on the approval status
    setPreviewDisabled(!areAllCollectionsApproved);
    console.log("areAllCollectionsApproved:", areAllCollectionsApproved);

    // Call the function to update cartNFTs
    updateCartNFTs();
  }, [groupedPreviewNFTs]);



  return (
    <div>
      

      <header className="bg-neutral-50 top-0 w-full sticky shadow-md">
        <nav className="container mx-auto px-6 py-3">
            <div className="flex justify-between items-center">
              <div>
              <a href="https://unsoldnfts.com/" className=""><img src={unsoldLogo} className="w-32"></img></a>
              </div>
              
                <div className=" flex items-center">
                  {showConnect ? (
                    <>
                    
                    <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={connectWallet}>
                    
                    {walletConnecting ? (
                    <>
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>Connecting...
                    </>
                    ) : (
                      "Connect Wallet"
                    )}


                    </button>
                    </>
                    ) : (
                      <p>{walletAddress && walletAddress.length > 0
                      ? (
                        <div className="bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed">Connected...{walletAddress.substring(walletAddress.length - 7)}</div>
                      ) : ( 
                        <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={connectWallet}>
                        {walletConnecting ? (
                          <>
                          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>Connecting...
                          </>
                          ) : (
                            "Connect Wallet"
                          )}
                        </button>
                      )}
                      </p>
                    )
                  }


                </div>
            </div>
            <div className="mt-4">
            <section>  
              <div className="container mx-auto py-6" >
                <div className="flex" id="ProgressStepper">   

                  <div className="w-1/4">
                    <div className="relative mb-2">
                      <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                        <span className="text-center text-white w-full">
                          1 
                        </span>
                      </div>
                    </div>
                    
                    {showConnect ? (
                      <div className="text-xs text-center 2xl:text-base font-extrabold">CONNECT WALLET</div>
                    ) :(
                      <div className="text-xs text-center 2xl:text-base font-semibold">CONNECT WALLET</div>
                    )
                    }

                    
                  </div>

                  <div className="w-1/4">
                    <div className="relative mb-2">

                      <div className="absolute flex align-center items-center align-middle content-center" style={{width: 'calc(100% - 2.5rem - 1rem)', top: '50%', transform: 'translate(-50%, -50%)'}}>
                        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        {showConnect ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showCard ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : showPreview ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : showCart ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : showReceipt ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                          ) : <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        }
                        </div>
                      </div>

                      {showConnect ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          2
                          </span>
                          </div>
                          </>
                        ) : showCard ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          2
                          </span>
                          </div>
                          </>
                        ) : showPreview ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          2
                          </span>
                          </div>
                          </>
                        ) : showCart ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          2
                          </span>
                          </div>
                          </>
                        ) : showReceipt ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                            2
                          </span>
                          </div>
                          </>
                        ) : 
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          2
                          </span>
                          </div>
                          </>
                        }
                      
                    </div>

                    {showCard ? (
                      <div className="text-xs text-center 2xl:text-base font-extrabold">SELECT NFTS</div>
                    ) :(
                      <div className="text-xs text-center 2xl:text-base font-semibold">SELECT NFTS</div>
                    )
                    }
                    
                  
                  </div>
                                  
                  <div className="w-1/4">
                    <div className="relative mb-2">

                      <div className="absolute flex align-center items-center align-middle content-center" style={{width: 'calc(100% - 2.5rem - 1rem)', top: '50%', transform: 'translate(-50%, -50%)'}}>
                        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        {showConnect ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showCard ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showPreview ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : showCart ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : showReceipt ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                          ) : <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        }
                        </div>
                      </div>

                      {showConnect ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          3
                          </span>
                          </div>
                          </>
                        ) : showCard ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          3
                          </span>
                          </div>
                          </>
                        ) : showPreview ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          3
                          </span>
                          </div>
                          </>
                        ) : showCart ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          3
                          </span>
                          </div>
                          </>
                        ) : showReceipt ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          3
                          </span>
                          </div>
                          </>
                        ) : 
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          3
                          </span>
                          </div>
                          </>
                        }
                      
                    </div>

                    {showPreview ? (
                      <div className="text-xs text-center 2xl:text-base font-extrabold">ALLOW ACCESS</div>
                    ) :(
                      <div className="text-xs text-center 2xl:text-base font-semibold">ALLOW ACCESS</div>
                    )
                    }
                    
                  
                  </div>

                  
                  <div className="w-1/4">
                    <div className="relative mb-2">
                      <div className="absolute flex align-center items-center align-middle content-center" style={{width: 'calc(100% - 2.5rem - 1rem)', top: '50%', transform: 'translate(-50%, -50%)'}}>
                        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        {showConnect ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showCard ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showPreview ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showCart ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : showReceipt ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : 
                        <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        }
                        </div>
                      </div>

                      {showConnect ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          4
                          </span>
                          </div>
                          </>
                        ) : showCard ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          4
                          </span>
                          </div>
                          </>
                        ) : showPreview ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          4
                          </span>
                          </div>
                          </>
                        ) : showCart ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          4
                          </span>
                          </div>
                          </>
                        ) : showReceipt ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          4
                          </span>
                          </div>
                          </>
                        ) : 
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          4
                          </span>
                          </div>
                          </>
                        }
                      
                    </div>
                    
                    {showCart ? (
                      <div className="text-xs text-center 2xl:text-base font-extrabold">SELL NFTS</div>
                    ) :(
                      <div className="text-xs text-center 2xl:text-base font-semibold">SELL NFTS</div>
                    )
                    }
                    
                  </div>

                  <div className="w-1/4">
                    <div className="relative mb-2">
                      <div className="absolute flex align-center items-center align-middle content-center" style={{width: 'calc(100% - 2.5rem - 1rem)', top: '50%', transform: 'translate(-50%, -50%)'}}>
                        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        {showConnect ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showCard ? (
                            <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showPreview ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showCart ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '0%' }}></div>
                        ) : showReceipt ? (
                          <div className="w-0 bg-green-300 py-1 rounded" style={{ width: '100%' }}></div>
                        ) : null}
                        </div>
                      </div>

                      {showConnect ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          5
                          </span>
                          </div>
                          </>
                        ) : showCard ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          5
                          </span>
                          </div>
                          </>
                        ) : showPreview ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full"> 
                          5
                          </span>
                          </div>
                          </>
                        ) : showCart ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center">
                          <span className="text-center  text-gray-600 w-full">
                          5
                          </span>
                          </div>
                          </>
                        ) : showReceipt ? (
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          5
                          </span>
                          </div>
                          </>
                        ) : 
                          <>
                          <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center">
                          <span className="text-center text-white w-full">
                          5
                          </span>
                          </div>
                          </>
                        }
                    </div>

                    {showReceipt ? (
                      <div className="text-xs text-center 2xl:text-base font-extrabold">GET RECEIPT</div>
                    ) :(
                      <div className="text-xs text-center 2xl:text-base font-semibold">GET RECEIPT</div>
                    )
                    }
                    
                  </div>

                </div>
              </div>
            </section>
          </div>

          
          {/* Buttons at the top */}
          <div className="">
            <div className="flex flex-wrap justify-center space-x-4">
              {showCard && (
                <>
                
                <div>
                {isConfirmDisabled ? (
                <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-blue-300 cursor-not-allowed" disabled>Confirm Selections</button>
                ) : (
                  <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={() => toggleComponent('preview')} disabled={isConfirmDisabled}>Confirm Selections</button>
                )}
                
                </div>
                
                </>
              )}

              {showPreview && (
                <>
                <div>
                <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={() => toggleComponent('card')}>Back</button>
                </div>
                <div>
                {isPreviewDisabled ? (
                <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-blue-300 cursor-not-allowed" disabled>Preview Sale</button>
                ) : (
                  <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={() => toggleComponent('cart')} disabled={isPreviewDisabled} >Preview Sale</button>
                )}
                
                </div>
                </>
              )}

              {showCart && (
                <>
                <div>
                <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={() => toggleComponent('preview')}>Back</button>
                </div>
                <div>
                {!checked ? (
                <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-blue-300 cursor-not-allowed" disabled>Sell Your NFTs</button>
                ) : (
                  <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={() => sellNfts()}>
                  {sellProcessing ? (
                <>
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>Processing...
                </>
                ) : (
                  "Sell Your NFTs"
                )}
                  </button>
                )}

                
                
                </div>
                </>
              )}

            </div>
            {showCard && (
                <>

                <div className="flex flex-wrap justify-center space-x-4 font-semibold">{confirmedText} Selected</div>
                </>
            )}
            {showPreview && (
                <>

                <div className="flex flex-wrap justify-center space-x-4 font-semibold">{previewText} Approved</div>
                </>
            )}
            
            
          </div>
          {/* <div> */}
          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded" onClick={() => {console.log(approvedNFTs)}}>log approved nfts:</button> */}
          {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded" onClick={(show) => {setShowNFTs(!showNFTs)}}>toggle</button> */}
          {/* </div> */}

        </nav>
      </header>

    <div className="w-full flex flex-col sm:flex-row flex-grow overflow-hidden">
    
      <main role="main" className="w-full h-full mb-80 flex-grow p-3 overflow-auto">

        
        
        {showConnect && (
        <div className="flex flex-col gap-y-4 justify-center">
          <>
          <div>
          <div className="flex justify-center text-3xl font-semibold">
          Turn your unsold NFTs into potential tax write-offs in just a few clicks.
          
          </div>
          <div className="flex justify-center text-sm">(This is not tax advice. Please consult with a tax professional as every tax situation is different.)</div>
          </div>
          <div className="flex justify-center">
          We'll buy your worthless NFTs and provide you with a receipt to maximize your investment loss come tax season.
          </div>
          <div className="flex justify-center">
          All you need to do is cover the gas and a small service fee of 0.001 ETH per NFT.
          </div>
          <div className="flex justify-center">
            Connect your wallet and get started!
          </div>
          <div className="flex justify-center">
          <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={connectWallet}>
          
          {walletConnecting ? (
            <>
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>Connecting...
            </>
            ) : (
              "Connect Wallet"
            )}
          </button>
          </div>
          </>
        </div>
        )}
        
          
                  
        

            {showPreview && (
              <div className="flex flex-col gap-y-4 justify-center">
              <div className="flex justify-center text-3xl font-semibold">
              ALLOW ACCESS
              </div>
              <div className="flex justify-center">
              You have selected the following NFTs for sale. For each collection, click on "ALLOW ACCESS" and your wallet will ask for access to each NFT collection to move forward.
              </div>
              <div className="flex justify-center">
              There is a small gas fee to approve each collection. You only need to approve each collection once.
              </div>
              <div className="flex justify-center mb-20">
              Once you approved all selected collections, click "Preview Sale" to sell your NFTs. Click the "Back" button if you need to make any changes.
              </div>
              <NFTPreview getCollectionApproval={getCollectionApproval} fetchData={fetchData} groupedPreviewNFTs={groupedPreviewNFTs} loading={loading} setLoadingCollection={setLoadingCollection} loadingCollections={loadingCollections} />
              </div>
            ) }
            
            {showCard && (
            <div>
            <div className="flex flex-col gap-y-4 justify-center">
              <div className="flex justify-center text-3xl font-semibold">
              SELECT NFTS
              </div>
              <div className="flex justify-center">
              Click to highlight and select NFTs you want to sell.
              </div>
              <div className="flex justify-center">
              Once you selected all the NFTs you want to sell, click on "Confirm Selections" button.
              </div>
              <div className="flex justify-center mb-20">
              You will get to review selected NFTs again on the next page.
              </div>
            </div>
              
              {Object.entries(groupedNFTs).map(([collectionName, nftsInCollection]) => (
                <div key={collectionName} className="mb-20">
                  <p className="font-bold flex flex-wrap gap-y-12 mt-4 gap-x-2 justify-center">{collectionName}</p>
                  <div className='flex flex-wrap gap-y-12 mt-4 gap-x-2 justify-center '>
                    {nftsInCollection.map((nft, index) => (
                    <NFTCard key={index} nft1={nft} addSelectedNFT={addSelectedNFT} selectedNFTs={selectedNFTs} index={index}/>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            )}

            {showCart && (
              <div>
              <div className="flex flex-col gap-y-4 justify-center">
              <div className="flex justify-center text-3xl font-semibold">
              SELL NFTS
              </div>
              <div className="flex justify-center">Your NFTs are ready!</div>
                <div className="flex justify-center">Click "Sell Your NFTs" and your wallet will prompt you to complete the transaction.  You will see:</div>
                <div className="flex justify-center">
                  <ul className="list-disc">
                  <li><b>Contract Interaction</b> - UnsoldNFTs service fee</li>
                  <li><b>Estimated Gas Fee</b> - the amount you pay for the blockchain to process the transaction.</li>
                  <li><b>Total</b> - the total amount you will be charged for the transaction</li>
                </ul></div>
                <div className="flex justify-center">
                
                
                <label>
                <input class="mr-2 leading-tight"
                  type="checkbox"
                  checked={checked}
                  onChange={handleChange}
                />
                <span>
                 I understand this is not tax advice, and I agree to UnsoldNFTs.com's Terms of Service.
                 </span>
                </label>
                </div>
                
              </div>
              
              <div className="flex mt-4 justify-center">
              
              {!checked ? (
                <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-blue-300 cursor-not-allowed" disabled>Sell Your NFTs</button>
                ) : (
                  <button className="inline-flex items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150" onClick={() => sellNfts()}>
                  {sellProcessing ? (
                <>
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>Processing...
                </>
                ) : (
                  "Sell Your NFTs"
                )}
                  </button>
                )}
              
              </div>
              <div className="flex flex-wrap gap-y-12 mt-4 gap-x-2 justify-center">
              
              <NFTCart cartNFTs={cartNFTs}/>
              </div>
              </div>
            )}

            {showReceipt && (
              <div>
                <div className="flex flex-col gap-y-4 justify-center">
                <div className="flex justify-center text-3xl font-semibold">
                GET RECEIPT
                </div>
                <NFTReceipt txUrl={txUrl} handleSubmit={handleSubmit} emailInputRef={emailInputRef} emailSubmit={emailSubmit} />
                </div>
              </div>
            )} 

      </main>
    </div>
    <Analytics />

    <footer className="bg-neutral-50 w-full flex flex-wrap gap-y-12 mt-4 gap-x-2 justify-center">
      <div className="grid grid-cols-1 justify-items-center">
        <p className="mt-8">Verified Smart Contract</p>
        <p className="mt-6"><img src={etherscanLogo} className="w-36"></img></p>
        <p className="mt-4"><a href="https://unsoldnfts.com/terms-of-service/" target="_blank">Terms of Service</a> - <a href="https://unsoldnfts.com/privacy-policy/" target="_blank">Privacy Policy</a></p>
      </div>
    </footer>


  </div>
  );
}

export default App;
