export const NFTCard = ({ nft1, addSelectedNFT, selectedNFTs, index }) => {
    function getImage() {
        let image;
        if (nft1.contract?.openSea?.imageUrl) {
            image = nft1.contract?.openSea?.imageUrl;
        }
        if (image === undefined && nft1.media.length !== 0) {
            image = nft1.media[0].gateway;
        }
        if (image === undefined && nft1.tokenUri) {
            image = nft1.tokenUri.gateway;
        }
        return image || "https://i.imgur.com/yT9EQzK.png";
    }

    return (
                <div className={selectedNFTs.includes(`${nft1.contract.name}_${index}`) ? "flex flex-col rounded-t-md border-solid border-8 border-yellow-300" : "flex flex-col border-none"}>

            <div>{nft1.id}</div>
            {/* <button onClick={() => {addSelectedNFT(index)}}> */}
            <button className="w-44" onClick={() => {addSelectedNFT(nft1.contract.name, index)}}>
            <div className="rounded-md">
                <img className="object-cover h-128 max-h-44 w-44" src={getImage()} ></img>
            </div>
            <div className="flex flex-col y-gap-2 px-2 py-3 bg-slate-100 rounded-b-md h-110 border-solid border-5 border-indigo-500">
                <div className="break-words">
                    {/* <h2 className="text-xl text-gray-800">{nft1.title}</h2> */}
                    <p className="text-gray-600">#{nft1.tokenId}</p>
                    {/* <p className="text-gray-600" >{nft1.contract.address}</p> */}
                </div>

                <div className="flex-grow mt-2">
                    {/* <p className="text-gray-600">{nft1.description}</p> */}
                </div>
            </div>

            </button>
        </div>
    )
}
