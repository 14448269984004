export const NFTReceipt = ({ txUrl, handleSubmit, emailInputRef, emailSubmit }) => {
    

    return (
        
        <div>
            <div className="flex flex-col gap-y-4 mt-4 gap-x-2 justify-center">
                <div className="flex justify-center">
                Great job on successfully selling your NFTs and streamlining your tax strategy for the year!   
                </div>
                <div className="flex justify-center">
                To view your transaction details click&nbsp;<span><a href={txUrl} target="_blank" className="text-blue underline font-bold">HERE</a></span>.    
                </div>
            </div>
            <div className="flex flex-col gap-y-4 mt-12 gap-x-2 items-center justify-center">
              <div className="w-full max-w-xl justify-center">
              <form class=" bg-white shadow-md rounded border items-center justify-center">
                <label class="block text-gray-700 text-lg font-bold mb-2 mx-8 my-8">
                Get your official transaction receipt
                </label>
                {!emailSubmit ? (
                <div class="flex items-center border-b border-teal-500 py-2">
                <input ref={emailInputRef} class="mx-8 mb-8 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="name@gmail.com"/>
                <button class="mx-8 mb-8 flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" type="button" onClick={handleSubmit}>
                Submit
                </button>  
                </div>
                ) : (
                  <div class="flex items-center border-b border-teal-500 py-2">
                    <label class="block text-gray-700 text-lg mb-2 mx-8">
                    Your email receipt has been sent!  Check your spam folder if you don't see the email in your inbox.
                    </label>
                  </div>
                  
                )}
              </form> 
              </div>
            </div>
        </div>
    );
}