import React, { useEffect, useState } from 'react';

export const NFTPreview = ({ getCollectionApproval, fetchData, groupedPreviewNFTs, loading, setLoadingCollection, loadingCollections }) => {
  
  

  function getImage(NFT) {
    let image;
    if (NFT.contract?.openSea?.imageUrl) {
        image = NFT.contract?.openSea?.imageUrl;
    }
    if (image === undefined && NFT.media.length !== 0) {
        image = NFT.media[0].gateway;
    }
    if (image === undefined && NFT.tokenUri) {
        image = NFT.tokenUri.gateway;
    }
    return image || "https://i.imgur.com/yT9EQzK.png";
  }

  // useEffect(() => {
  //   // Create an array of promises
  //   const approvalPromises = selectedNFTs.map(async (indexString) => {
  //     const [collectionName] = indexString.split('_');
  //     const isApproved = await checkCollectionApproval(groupedNFTs[collectionName][0]?.contract?.address);

  //     if (!groupedPreviewNFTs[collectionName]) {
  //       groupedPreviewNFTs[collectionName] = { nfts: [], approved: isApproved };
  //     }

  //     const indexNumber = parseInt(indexString.split('_')[1], 10);
  //     const NFT = groupedNFTs[collectionName][indexNumber];

  //     // Check if the NFT is already present in the array before pushing it
  //     const isNFTAlreadyAdded = groupedPreviewNFTs[collectionName].nfts.some((existingNFT) => existingNFT.index === indexNumber);
  //     if (!isNFTAlreadyAdded) {
  //       groupedPreviewNFTs[collectionName].nfts.push({ ...NFT, index: indexNumber });
  //     }
  //   });

  //   // Use Promise.all to wait for all promises to resolve
  //   Promise.all(approvalPromises)
  //     .then(() => {
  //       // Set the state with the updated groupedPreviewNFTs
  //       setGroupedPreviewNFTs({ ...groupedPreviewNFTs });
  //       console.log("groupedPreviewNFTs: ", groupedPreviewNFTs)
  //       setLoading(false);
  //     });
  // }, [selectedNFTs, groupedNFTs, checkCollectionApproval]);

  

  
  // useEffect(() => {
  //   console.log('Grouped Preview NFTs Updated::::::::::::', groupedPreviewNFTs);
  // }, [groupedPreviewNFTs]);

  if (loading) {
    // You might want to return a loading indicator here
    return <div>Loading...</div>;
  }

  // Render the component after all promises have resolved
  return (
    <div className="justify-center">
      {Object.keys(groupedPreviewNFTs)
        .sort((a, b) => a.localeCompare(b))
        .map((collectionName) => (
          <div key={collectionName}>
            <p className="font-bold flex flex-wrap gap-y-12 mt-4 gap-x-2 justify-center">{collectionName}</p>

            {groupedPreviewNFTs[collectionName].approved ? (
              <>
            <div className="flex flex-wrap gap-y-12 mt-4 gap-x-2 justify-center">
            <div className="access-granted-label">
               <span role="img" aria-label="checkmark">✅</span>Access Granted
            </div>
            {/* <button
            //className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
            className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
            onClick={async () => {
              console.log("collectionNamecollectionName:", collectionName);
              setLoadingCollection(collectionName, true);
              console.log("before setLoadingCollections..", loadingCollections);
              await getCollectionApproval(groupedPreviewNFTs, collectionName, false); // Fetch data again after approval
              setLoadingCollection(collectionName, false);
              console.log("afer setLoadingCollections..", loadingCollections);
            }}
          >
            {loadingCollections[collectionName] ? (
              <>
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>Processing...
              </>
            ) : (
              "REVOKE ACCESS"
            )}
          </button> */}
          </div>
          </>
          ) : (
          <div className="flex flex-col gap-y-4 justify-center">
            <div className="flex justify-center">Allow access for UnsoldNFTs to purchase selected items from the <i>{collectionName}</i> collection.</div>
            <div className="flex justify-center">
            <button
              //className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
              className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
              onClick={async () => {
                console.log("collectionNamecollectionName:", collectionName);
                setLoadingCollection(collectionName, true);
                console.log("before setLoadingCollections..", loadingCollections);
                await getCollectionApproval(groupedPreviewNFTs, collectionName, true);
                setLoadingCollection(collectionName, false);
                console.log("afer setLoadingCollections..", loadingCollections);
              }}
            >
              {loadingCollections[collectionName] ? (
                <>
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>Processing...
                </>
              ) : (
                "ALLOW ACCESS"
              )}
            </button>
            </div>
          </div>
          )}
            {/* <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
              onClick={() => {
                getCollectionApproval(groupedPreviewNFTs, collectionName);
              }}
            >
              ALLOW ACCESS
            </button> */}

            <div className="flex flex-wrap gap-y-12 mt-4 mb-20 gap-x-2 justify-center">
              {groupedPreviewNFTs[collectionName].nfts.map((NFT, index) => (
                <div key={`${collectionName}_${index}`} className="w-44 flex flex-col border-none justify-center">
                  <div className="rounded-md">
                    <img className="object-cover h-128 max-h-44 w-44" src={getImage(NFT)} alt={`NFT Preview ${index}`} />
                  </div>
                  <div className="flex flex-col y-gap-2 px-2 py-3 bg-slate-100 rounded-b-md h-110 border-solid border-5 border-indigo-500 justify-center">
                    <div className="break-words">
                      <p className="text-gray-600 justify-center">#{NFT.tokenId}</p>
                    </div>
                    
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};
